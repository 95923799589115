<template>
  <router-view/>
</template>

<style lang="scss">
@import '@/assets/style/common.scss';
*{
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100vh;
  // background: #212121;
  color: $textColor;
}
</style>
