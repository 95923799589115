import axios from 'axios' // 引入axios
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router/index'
import { getToken } from '@/utils'
import e from '../../env.js'
 
const service = axios.create({
  baseURL: e.dev.VUE_APP_API_URL,
  timeout: 1000 * 60 * 30
})
let acitveAxios = 0
let timer
const showLoading = () => {
  acitveAxios++
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    if (acitveAxios > 0) {
      // emitter.emit('showLoading')
    }
  }, 400)
}

const closeLoading = () => {
  acitveAxios--
  if (acitveAxios <= 0) {
    clearTimeout(timer)
    // emitter.emit('closeLoading')
  }
}
// http request 拦截器
service.interceptors.request.use(config => {
  // console.log(getToken())
  if(getToken()) {
		config.headers['Authorization'] = 'Bearer ' + getToken()
	}
  if (!config.donNotShowLoading) {
    showLoading()
  }

    return config
  }, error => {
    closeLoading()
    return error
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {
    // const userStore = useUserStore()
    closeLoading()
    if (response.headers['new-token']) {
      //
    }
    if (response.data.code === 200 || response.headers.success === 'ok') {
      if (response.headers.msg) {
        response.data.msg = decodeURI(response.headers.msg)
      }
      return response.data
    } else {

      const res = response.data.match?response.data.match(/\d+/g):[]
      if (response.data.data && response.data.data.reload) {
        localStorage.clear()
        // router.push({ name: 'Login', replace: true })
        return Promise.reject(response.data.msg ? response.data : response)
      }else if(res?.length&& res[0]== '901'){
        localStorage.clear()
        // router.push('/login')
      }else{
        return Promise.reject(response.data.msg ? response.data : response)
      }
     
    }
  },
  error => {
    closeLoading()

    if (!error.response) {
      // ElMessageBox.confirm(`
      //   <p>检测到请求错误</p>
      //   <p>${error}</p>
      //   `, '请求报错', {
      //   dangerouslyUseHTMLString: true,
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: '稍后重试',
      //   cancelButtonText: '取消'
      // }).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      // })
      return
    }

    switch (error.response.status) {
      case 500:
        ElMessageBox.confirm(`
        <p>检测到接口错误${error}</p>
        <p>错误码<span style="color:red"> 500 </span>：此类错误内容常见于后台panic，请先查看后台日志，如果影响您正常使用可强制登出清理缓存</p>
        `, '接口报错', {
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          confirmButtonText: '清理缓存',
          cancelButtonText: '取消'
        })
          .then(() => {
            localStorage.clear()
            // router.push({ name: 'Login', replace: true })
          }).catch(() => {
            localStorage.clear();
          })
        break
      case 404:
        ElMessageBox.confirm(`
          <p>检测到接口错误${error}</p>
          <p>错误码<span style="color:red"> 404 </span>：此类错误多为接口未注册（或未重启）或者请求路径（方法）与api路径（方法）不符--如果为自动化代码请检查是否存在空格</p>
          `, '接口报错', {
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          confirmButtonText: '我知道了',
          cancelButtonText: '取消'
        }).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
        break
    }
    return error
  }
)
export default service
