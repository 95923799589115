module.exports = {
  // 测试环境 接口请求地址
  test: {
    MODE: 'test',
    VUE_APP_API_URL: 'https://api.jx.cool',
  },
  // 开发环境 接口请求地址
  dev: {
    MODE: 'dev',
    VUE_APP_API_URL: 'https://api.jx.cool',
  },
  // 生产环境 接口请求地址，非独立部署默认为空
  prod: {
    MODE: 'prod',
    VUE_APP_API_URL: 'https://api.jx.cool',
  }
}