import Cookies from 'js-cookie'
export const getCache = name => {
  return localStorage.getItem(name) || ''
}

export const setCache = (name, value) => {
  localStorage.setItem(name, value)
}

export const removeCache = name => {
  localStorage.removeItem(name)
}

export const setCookies = (name,value, expire=7) => {
  Cookies.set(name, value, { expires: expire });
} 

export const getCookies = (name) => {
  return Cookies.get(name);
} 

export const removeCookies = (name) => {
  return Cookies.remove(name);
} 

export const logOut =()=>{
  
}