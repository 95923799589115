import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    meta: {icon: 'home.png',title: '首页',isRoutepage: true},
    component: () => import( '../views/home/IndexView.vue'),
    redirect: 'home',
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          icon: 'home.png',
          title: '首页',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/HomeView.vue')
      },
      {
        path: '/TextToImage',
        name: 'TextToImage',
        meta: {
          icon: 'textToImage.png',
          title:'文生图',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "TextToImage" */ '../views/customImage/TextToImage.vue')
      },
      {
        path: '/imageToImage',
        name: 'imageToImage',
        meta: {
          icon: 'imageToImage.png',
          title:'图生图',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "imageToImage" */ '../views/customImage/imageToImage.vue')
      },
      {
        path: '/photoToImage',
        name: 'photoToImage',
        meta: {
          icon: 'photoToImage.png',
          title:'垫图',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "photoToImage" */ '../views/customImage/photoToImage.vue')
      },
      {
        path: '/condition',
        name: 'conditionToImage',
        meta: {
          icon: 'condition.png',
          title:'条件生图',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "conditionToImage" */ '../views/customImage/conditionToImage.vue')
      },
      {
        path: '/model',
        name: 'mainCustomization',
        meta: {
          icon: 'style.png',
          title:'模型广场',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "mainCustomization" */ '../views/custonStyle/mainCustomization.vue')
      },
      {
        path: '/model/details/:orderId',
        name: 'mainCustonDetail',
        meta: {
          icon: 'modal.png',
          title:'模型广场图片详情',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "mainCustonDetail" */ '../views/custonStyle/secondpage/ImageStyleSecond.vue')
      },
      {
        path: '/image',
        name: 'custonizationGround',
        meta: {
          icon: 'modal.png',
          title:'作品广场',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "custonizationGround" */ '../views/custonStyle/custonizationGround.vue')
      },
      {
        path: '/image/details/:orderId',
        name: 'CustonDetail',
        meta: {
          icon: 'modal.png',
          title:'作品广场图片详情',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "CustonDetail" */ '../views/custonStyle/secondpage/ImageStyleFirst.vue')
      },
      {
        path: '/myStyle',
        name: 'myStyle',
        meta: {
          icon: 'square.png',
          title:'我的风格',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "myStyle" */ '../views/myself/myStyle.vue')
      },
      {
        path: '/myWorks',
        name: 'myWorks',
        meta: {
          icon: 'works.png',
          title:'我的作品',
          isRoutepage: true
        },
        component: () => import(/* webpackChunkName: "myWorks" */ '../views/myself/myWorks.vue')
      },
      {
        path: '/personal',
        name: 'Personal',
        meta: {
          icon: 'icon_avatar.png',
          title:'个人中心',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "Personal" */ '../views/myself/personal.vue')
      },
      {
        path: '/concat',
        name: 'Concat',
        meta: {
          icon: 'contact.png',
          title:'联系我们',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "Concat" */ '../views/concat/contact.vue')
      },
      {
        path: '/customization',
        name: 'Customization',
        meta: {
          icon: '',
          title: '训练模型',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "Customization" */ '../views/myself/myCustomization.vue')
      },
      {
        path: '/vip',
        name: 'Vip',
        meta: {
          icon: 'icon_avatar.png',
          title:'开通会员',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "Vip" */ '../views/vip/Vip.vue')
      },

      {
        path: '/article',
        name: 'article',
        meta: {
          icon: 'contact.png',
          title:'文章详情',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "Concat" */ '../views/article/index.vue')
      },
    ]
  },
  {
    path: '/plugin/login',
    name: 'Login',
    meta: {
      icon: 'icon_avatar.png',
      title:'登录',
      isRoutepage: false
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/plugin/login/index.vue')
  },
  {
    path: '/plugin/condition',
    meta: {
      icon: 'home.png',
      title: '首页',
      isRoutepage: false
    },
    component: () => import( '../views/plugin/home/index.vue'),
    redirect: 'conditionsToImage',
    children: [
      {
        path: '',
        name: 'conditionsToImage',
        meta: {
          icon: 'condition.png',
          title:'条件生图',
          isRoutepage: false
        },
        component: () => import(/* webpackChunkName: "condition" */ '../views/plugin/condition/index.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
//导航守卫
// router.beforeEach((to,form,next) => {
//   // console.log(to);
//   if(to.path === '/TextToImage' || to.psth === '/imageToImage' || to.psth === '/conditionToImage') {
    
//   }
//   next();
// })

export default router
