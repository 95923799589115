import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入字体图标方法
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App);
// 字体图标引入（全局注册）
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
// app.component(Fold.name,Fold)
app.use(store).use(router).use(ElementPlus).mount('#app')