import { getTalkCount } from '@/api';
import { createStore } from 'vuex'

const myHeaders = {
  Authorization: "Bearer " + localStorage.getItem('token'),
};

export default createStore({
  state: {
    widthsize: {
      leftpx: '200px',
      // rightpx: 18
    },
    visible: false,
    loginperson: false,
    detailData: null,
    imgurl: require('@/assets/images/icon_avatar.png'),
    pageNum: 1,
    pageNumVis: true,
    talkCount:10
  },
  getters: {
  },
  mutations: {
    changeWidthsize(state,payload) {
      state.widthsize.leftpx = payload;
    },
    changeTalkCount(state,payload){
      getTalkCount(myHeaders).then(res=>{
        if(res)state.talkCount = res.data.num
      })
    },

    changeVisible(state,payload) {
      state.visible = payload
    },
    changeLoginpersonal(state,payload) {
      state.loginperson = payload;
    },
    changedetailData(state,payload) {
      state.detailData = payload;
    },
    changeAvatar(state,payload) {
      state.imgurl = payload;
    },
    changePageNum(state,num) {
      if(state.pageNumVis) {
        state.pageNum++;
      }
      if(num) {
        state.pageNum = num;
      }
    },
    changeVis(state,payload) {
      state.pageNumVis = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
