import service from "@/utils/request";
// import service_api from "@/utils/request_api";

const key ='app_id=fbaeedb6ce&identity=039e22e7acff2ccbac34f85e3b6cc0e5'



//下面vue----------------------------------------------

export const logOut = data => service.get('/api/logout', data)
export const vipOverdueTime = () => service.get('/api/user/member/overdue/time')
// export const restPWD = (data) => service.post('/api/register/reset',data)
export const getVerify = () => service.get('/api/verify_code')
 
// 获取发短信的code
export const getVerifyCodeKey = () => service.get('/api//verify_code')


// 获安全验证图
export const getAjcaptcha = params => service.get('/api/ajcaptcha', { params })

// 安全验证图认证
export const handleAjcheck = data => service.post('/api/ajcheck', data)


// 获取微信appid
export const getAppId = params => service.get('/api/pc/get_appid', { params })

// 微信code 登录
export const loginWithQrCode = params => service.get('/api/pc/wechat_auth', { params })


////new


// 新
// 生成对话
export const createAi = (myHeaders,data) => {
  return service({
    url: "/api/v1/talk.create?" + key,
    method: "post",
    headers: myHeaders,
    data
  });
};

//对话列表
export const getTalkList = (myHeaders,page,pageSize,id) => {
  if(id){
    return service({
      url: "/api/v1/talk.list?recommend_status="+id+'&page='+page+'&pageSize='+pageSize+'&'+ key,
      method: "get",
      headers: myHeaders,
    });
  }else{
    return service({
      url: "/api/v1/talk.list?page="+page+'&pageSize='+pageSize+'&'+ key,
      method: "get",
      headers: myHeaders,
    });
  }

};

//对话详情
export const getTalkDetail = (id,myHeaders) => {
  return service({
    url: "/api/v1/talk.detail?" + key+'&tid='+id,
    method: "get",
    headers: myHeaders,
  });
};


//上传图片
export const uploadImage = (data,myHeaders) => {
  return service({
    url: "/api/v1/upload.file?" + key,
    method: "post",
    headers: myHeaders,
    data:data
  });
};

export const uploadMutilImage = (data,myHeaders) => {
  return service({
    url: "/api/v1/upload.files?" + key,
    method: "post",
    headers: myHeaders,
    data:data
  });
};

export const imageProgress = (id_task,id,myHeaders) => {
  return service({
    url: '/api/v1/talk.process?'+key+'&id_task='+id_task+'&tid='+id,
    method: "get",
    headers:myHeaders,
  });
};


// 训练
export const train = (data,myHeaders) => {
  return service({
    url: '/api/v1/model.create?'+key,
    method: "post",
    data,
    headers:myHeaders,
  });
};  

// 训练列表
export const trainList = (myHeaders,page,pageSize,id) => {
  return service({
    url: '/api/v1/model.list?'+ key,
    method: "get",
    headers:myHeaders,
  });
};

export const trainDetail = (mid,myHeaders) => {
  return service({
    url: '/api/v1/model.info?'+key+'&mid='+mid,
    method: "get",
    headers:myHeaders,
  });
};  

export const changeStatus = (data,myHeaders) => {
  return service({
    url: '/api/v1/model.changeStatus?'+key,
    method: "post",
    data,
    headers:myHeaders,
  });
};  

// 账号密码登录
export const loginWithPwd = (data) => service.post('/api/v1/user.login?'+key,data)

// 手机验证码登录和注册
export const loginWithCode =  (data) => service.post('/api/v1/login.mobile?'+key,data)

// 获取验证码
export const getVerifyCode = data => service.post('/api/v1/register.verify?'+key, data)

// 获取微信二维码
export const getQrcode = data => service.get('/api/v1/wechat.qrcode?'+key)
// 微信扫码
export const getWechatScan = data => service.post('/api/v1//api/v1/wechat.doscan?'+key,data)

// 获取个人信息
export const getUserInfo = (myHeaders) => service.get('/api/v1/user.info?'+key,{headers:myHeaders})

export const chargeVip = (data, myHeaders) => {
  return service({
    url: 'wechatpay/createOrderPc',
    method: "post",
    data,
    headers: myHeaders,
  });
};

export const checkPay = (data, myHeaders) => {
  return service({
    url: 'wechatpay/check_pay',
    method: "post",
    data,
    headers: myHeaders,
  });
};

export const userEdit = (data, myHeaders) => {
  return service({
    url: '/api/v1/user.update?'+key,
    method: "post",
    data,
    headers: myHeaders,
  });
};

//绑定微信
export const getBindCode = (myHeaders) => {
  return service({
    url: '/api/v1/user.bind_wx?'+key,
    method: "post",
    headers: myHeaders,
  });
};

//解绑微信
export const getUnBindCode = (myHeaders) => {
  return service({
    url: '/api/v1/user.unbind_wx?'+key,
    method: "post",
    headers: myHeaders,
  });
};

// 忘记密码
export const resetPwd = (data) => service.post('/api/v1/user.change_pwd?'+key,data)
//重置密码
export const restPWD = (myHeaders,data) => {
  return service({
    url: '/api/v1/user.change_pwd?'+key,
    method: "post",
    headers: myHeaders,
    data,
  });
};  



//绑定手机
export const bindPhone = (data,myHeaders) => {
  return service({
    url: '/api/v1/user.bind_phone?'+key,
    method: "post",
    headers: myHeaders,
    data
  });
}; 


export const getUserLevel = (myHeaders) => {
  return service({
    url: '/api/v1/user.level?'+key,
    method: "get",
    headers: myHeaders,
  });
}; 

export const getLevelInfo = (id,myHeaders) => {
  return service({
    url: '/api/v1/user.level_info?id='+id + '&'+key,
    method: "get",
    headers: myHeaders,
  });
}; 

//文章列表
export const getArticleInfo = (page,pageSize) => {
  return service({
    url: '/api/article.list?page='+page+'&pageSize='+pageSize,
    method: "get"
  });
}

//文章详情
export const getArticleDetail = (article_id) => {
  return service({
    url: '/api/article.info?'+key+'&article_id='+ article_id,
    method: "get"
  });
}

//模型列表
export const getmodelList = (myHeaders,page,pageSize,id) => {
  return service({
    url: "/api/v1/model.list?recommend_status="+id+'&page='+page+'&pageSize='+pageSize+'&'+ key,
    method: "get",
    headers: myHeaders,
  });
};

//获取对话次数
export const getTalkCount =async  (myHeaders) => {
  return service({
    url: "/api/v1/user.user_num?"+key,
    method: "get",
    headers: myHeaders,
  });
};

//创建蒙版
export const createSam = (myHeaders,data) => {
  return service({
    url: "/api/v1/talk.runsam?" + key,
    method: "post",
    headers: myHeaders,
    data
  });
};

export const runSketch = (myHeaders,data) => {
  return service({
    url: "/api/v1/talk.runsketch?" + key,
    method: "post",
    headers: myHeaders,
    data
  });
};
