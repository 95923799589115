import { getCache, setCache, removeCache } from './storage'

// token Name
const tokenKey = () => {
  return '_AI_TK_'
}

// 设置token
export const setToken = token => setCache(tokenKey(), token)

// 获取token
export const getToken = () => getCache(tokenKey()) || ''

// 清除token
export const removeToken = () => removeCache(tokenKey())

// 清除所有
export const removeCacheAll = () => {
  removeToken()
}

// 类型检查
const checkParamsType = (p) => Object.prototype.toString.call(p)

export const isObject = p => checkParamsType(p) === '[object Object]'

// 获取验证码倒计时
export const countDown = (cb, done) => {
  let time = 60
  let text = `${time}s`
  cb(text)
  const timer = setInterval(() => {
    time--
    text = `${time}s`
    cb(text)
    if (time < 1) {
      text = '发送'
      cb(text)
      done()
      clearInterval(timer)
    }
  }, 1000)
}

// 数组对象去重
export const formatArr = (oldArr, newArr, key) => {
  if (!newArr) return []
  const keys = oldArr.map(item => item[key])
  const arr = []
  newArr.forEach(item => {
    if (keys.indexOf(item[key]) === -1) {
      arr.push(item)
    }
  })
  return arr
}

// 判断是否手机号
export function isMobile (value) {
  return /^1[0-9]{10}$/.test(value)
}